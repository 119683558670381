export const resolutionsConstants = {
	//#region Resolution
	GetResolutions: {
		REQUEST : 'GET_RESOLUTIONS_REQUEST',
		SUCCESS : 'GET_RESOLUTIONS_SUCCESS',
		FAILURE : 'GET_RESOLUTIONS_FAILURE',
		CLEAR 	: 'GET_RESOLUTIONS_CLEAR'
	},
	GetResolutionForm: {
		REQUEST : 'GET_RESOLUTION_FORM_REQUEST',
		SUCCESS : 'GET_RESOLUTION_FORM_SUCCESS',
		FAILURE : 'GET_RESOLUTION_FORM_FAILURE',
		CLEAR   : 'GET_RESOLUTION_FORM_CLEAR'
	},
	CreateResolution: {
		REQUEST : 'CREATE_RESOLUTION_REQUEST',
		SUCCESS : 'CREATE_RESOLUTION_SUCCESS',
		FAILURE : 'CREATE_RESOLUTION_FAILURE'
	},
	EditResolution: {
		REQUEST : 'EDIT_RESOLUTION_REQUEST',
		SUCCESS : 'EDIT_RESOLUTION_SUCCESS',
		FAILURE : 'EDIT_RESOLUTION_FAILURE'
	},
	EditResolutionProject: {
		REQUEST : 'EDIT_RESOLUTION_PROJECT_REQUEST',
		SUCCESS : 'EDIT_RESOLUTION__PROJECT_SUCCESS',
		FAILURE : 'EDIT_RESOLUTION_PROJECT_FAILURE'
	},
	SignResolution: {
		REQUEST : 'SIGN_RESOLUTION_REQUEST',
		SUCCESS : 'SIGN_RESOLUTION_SUCCESS',
		FAILURE : 'SIGN_RESOLUTION_FAILURE'
	},
	DownloadResolutionFile: {
		REQUEST : 'DOWNLOAD_RESOLUTION_FILE_BY_ID_REQUEST',
		SUCCESS : 'DOWNLOAD_RESOLUTION_FILE_BY_ID_SUCCESS',
		FAILURE : 'DOWNLOAD_RESOLUTION_FILE_BY_ID_FAILURE'
	},
	DeleteResolution: { 
		REQUEST : 'DELETE_RESOLUTION_REQUEST',
		SUCCESS : 'DELETE_RESOLUTION_SUCCESS',
		FAILURE : 'DELETE_RESOLUTION_FAILURE'
	},
	DeclineResolution: {
		REQUEST : 'DECLINE_RESOLUTION_REQUEST',
		SUCCESS : 'DECLINE_RESOLUTION_SUCCESS',
		FAILURE : 'DECLINE_RESOLUTION_FAILURE'
	},
	GetResolutionChangeHistory: {
		REQUEST : 'GET_RESOLUTION_HISTORY_CHANGE_REQUEST',
		SUCCESS : 'GET_RESOLUTION_HISTORY_CHANGE_SUCCESS',
		FAILURE : 'GET_RESOLUTION_HISTORY_CHANGE_FAILURE',
		CLEAR   : 'GET_RESOLUTION_HISTORY_CHANGE_CLEAR'
	},
	GetResolutionFilesInfo: { 
		REQUEST : "GET_RESOLUTION_FILE_INFO_REQUEST",
		SUCCESS : "GET_RESOLUTION_FILE_INFO_SUCCESS",
		FAILURE : "GET_RESOLUTION_FILE_INFO_FAILURE",
		CLEAR   : "GET_LIST_RA_CLEAR"
	},
	ClearResolutionActionsResult: 'CLEAR_RESOLUTION_ACTIONS_RESULT',
	//#endRegion
	//#region ResolutionInfo
	GetResolutionInfo: {
		REQUEST : 'GET_RESOLUTION_INFO_REQUEST',
		SUCCESS : 'GET_RESOLUTION_INFO_SUCCESS',
		FAILURE : 'GET_RESOLUTION_INFO_FAILURE',
		CLEAR	: 'GET_RESOLUTION_INFO_CLEAR'
	}, 	
	//#endRegion		  
	//#region ExecutorResult
	GetResolutionExecutorsList: {
		REQUEST : 'GET_RESOLUTION_EXECUTORS_LIST_REQUEST',
		SUCCESS : 'GET_RESOLUTION_EXECUTORS_LIST_SUCCESS',
		FAILURE : 'GET_RESOLUTION_EXECUTORS_LIST_FAILURE',
		CLEAR   : 'GET_RESOLUTION_EXECUTORS_LIST_CLEAR'
	},    
	CreateExecutionResult: {
		REQUEST : 'CREATE_EXECUTION_RESULT_REQUEST',
		SUCCESS : 'CREATE_EXECUTION_RESULT_SUCCESS',
		FAILURE : 'CREATE_EXECUTION_RESULT_FAILURE'
	},
	UpdateExecutionResult: {
		REQUEST : 'UPDATE_EXECUTION_RESULT_REQUEST',
		SUCCESS : 'UPDATE_EXECUTION_RESULT_SUCCESS',
		FAILURE : 'UPDATE_EXECUTION_RESULT_FAILURE'
	},
	ApproveExecutionResult: {
		REQUEST : 'APPROVE_EXECUTION_RESULT_REQUEST',
		SUCCESS : 'APPROVE_EXECUTION_RESULT_SUCCESS',
		FAILURE : 'APPROVE_EXECUTION_RESULT_FAILURE'
	},
	DeclineExecutionResult: {
		REQUEST : 'DECLINE_EXECUTION_RESULT_REQUEST',
		SUCCESS : 'DECLINE_EXECUTION_RESULT_SUCCESS',
		FAILURE : 'DECLINE_EXECUTION_RESULT_FAILURE'
	},
	ClearResolutionExecutorResultActionsResult: 'CLEAR_RESOLUTION_EXECUTOR_RESULT_ACTIONS_RESULT',
	//#endRegion
	//#region SummaryResult
	GetResolutionSummaryResult: {
		REQUEST : 'GET_RESOLUTION_SUMMARY_RESULT_REQUEST',
		SUCCESS : 'GET_RESOLUTION_SUMMARY_RESULT_SUCCESS',
		FAILURE : 'GET_RESOLUTION_SUMMARY_RESULT_FAILURE',
		CLEAR   : 'GET_RESOLUTION_SUMMARY_RESULT_CLEAR'
	}, 
	CreateSummaryResult: {
		REQUEST : 'CREATE_SUMMARY_RESULT_REQUEST',
		SUCCESS : 'CREATE_SUMMARY_RESULT_SUCCESS',
		FAILURE : 'CREATE_SUMMARY_RESULT_FAILURE'
	},
	UpdateSummaryResult: {
		REQUEST : 'UPDATE_SUMMARY_RESULT_REQUEST',
		SUCCESS : 'UPDATE_SUMMARY_RESULT_SUCCESS',
		FAILURE : 'UPDATE_SUMMARY_RESULT_FAILURE'
	},
	SendSummaryResultToSign: {
		REQUEST: 'SEND_SUMMARY_RESULT_TO_SIGN_REQUEST',
		SUCCESS: 'SEND_SUMMARY_RESULT_TO_SIGN_SUCCESS',
		FAILURE: 'SEND_SUMMARY_RESULT_TO_SIGN_FAILURE'
	},
	SendSummaryResultForRevision: {
		REQUEST: 'SEND_SUMMARY_RESULT_FOR_REVISION_REQUEST',
		SUCCESS: 'SEND_SUMMARY_RESULT_FOR_REVISION_SUCCESS',
		FAILURE: 'SEND_SUMMARY_RESULT_FOR_REVISION_FAILURE'
	},
	GetSummaryResultFilesInfo: {
		REQUEST: 'GET_SUMMARY_RESULT_FILES_INFO_REQUEST',
		SUCCESS: 'GET_SUMMARY_RESULT_FILES_INFO_SUCCESS',
		FAILURE: 'GET_SUMMARY_RESULT_FILES_INFO_FAILURE',
		CLEAR: 'GET_SUMMARY_RESULT_FILES_INFO_CLEAR'
	},
	SignSummaryResult: {
		REQUEST: 'SIGN_SUMMARY_RESULT_REQUEST',
		SUCCESS: 'SIGN_SUMMARY_RESULT_SUCCESS',
		FAILURE: 'SIGN_SUMMARY_RESULT_FAILURE'
	},
	ClearResolutionSummaryResultActionsResult: 'CLEAR_RESOLUTION_SUMMARY_RESULT_ACTIONS_RESULT',
	//#endRegion
	//#region IntermediateResult
	GetIntermediateAppealResultsPaged: {
		REQUEST : 'GET_INTERMEDIATE_APPEAL_RESULTS_PAGED_REQUEST',
		SUCCESS : 'GET_INTERMEDIATE_APPEAL_RESULTS_PAGED_SUCCESS',
		FAILURE : 'GET_INTERMEDIATE_APPEAL_RESULTS_PAGED_FAILURE',
		CLEAR	: 'GET_INTERMEDIATE_APPEAL_RESULTS_PAGED_CLEAR'
	},
	GetIntermediateAppealResult: {
		REQUEST : 'GET_INTERMEDIATE_APPEAL_RESULT_REQUEST',
		SUCCESS : 'GET_INTERMEDIATE_APPEAL_RESULT_SUCCESS',
		FAILURE : 'GET_INTERMEDIATE_APPEAL_RESULT_FAILURE',
		CLEAR	: 'GET_INTERMEDIATE_APPEAL_RESULT_CLEAR'
	},
	CreateIntermediateAppealResult: {
		REQUEST : 'CREATE_INTERMEDIATE_APPEAL_RESULT_REQUEST',
		SUCCESS : 'CREATE_INTERMEDIATE_APPEAL_RESULT_SUCCESS',
		FAILURE : 'CREATE_INTERMEDIATE_APPEAL_RESULT_FAILURE'
	},
	UpdateIntermediateAppealResult: {
		REQUEST : 'UPDATE_INTERMEDIATE_APPEAL_RESULT_REQUEST',
		SUCCESS : 'UPDATE_INTERMEDIATE_APPEAL_RESULT_SUCCESS',
		FAILURE : 'UPDATE_INTERMEDIATE_APPEAL_RESULT_FAILURE'
	},
	SendIntermediateAppealResultForRevision: {
		REQUEST : 'SEND_INTERMEDIATE_APPEAL_RESULT_FOR_REVISION_REQUEST',
		SUCCESS : 'SEND_INTERMEDIATE_APPEAL_RESULT_FOR_REVISION_SUCCESS',
		FAILURE : 'SEND_INTERMEDIATE_APPEAL_RESULT_FOR_REVISION_FAILURE'
	},
	SignIntermediateAppealResult: {
		REQUEST : 'SIGN_INTERMEDIATE_APPEAL_RESULT_REQUEST',
		SUCCESS : 'SIGN_INTERMEDIATE_APPEAL_RESULT_SUCCESS',
		FAILURE : 'SIGN_INTERMEDIATE_APPEAL_RESULT_FAILURE'
	},
	GetIntermediateAppealResultFilesInfo: {
		REQUEST : 'GET_INTERMEDIATE_APPEAL_RESULT_FILES_INFO_RESUEST',
		SUCCESS : 'GET_INTERMEDIATE_APPEAL_RESULT_FILES_INFO_SUCCESS',
		FAILURE : 'GET_INTERMEDIATE_APPEAL_RESULT_FILES_INFO_FAILURE',
		CLEAR   : 'GET_INTERMEDIATE_APPEAL_RESULT_FILES_INFO_CLEAR'
	},
	ClearIntermediateAppealResultActionsResult: 'CLEAR_INTERMEDIATE_APPEAL_RESULT_ACTIONS_RESULT',
	//#endRegion
}

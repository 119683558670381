import {
    reOrgAppealMissionsConstants
} from './constants'
import api from './api'
import {
    defAction
} from '../../../../../helpers/defaultAction'
import downloadFile from '../../../../../helpers/downloadFile'
  
export const reOrgAppealMissionsActions = {
    CreateReOrgAppealMission,
    ReadReOrgAppealMission,
    RegisterReOrgAppealMission,  
    UpdateReOrgAppealMissionByReceiver, 
    CreateReOrgAppealMissionResponse, 
    GetReOrgAppealMissionsList,
    GetReOrgAppealMissionsPaged, 
    GetReOrgAppealMissionsForReOrgAppealPaged,
    GetReOrgAppealMissionFile, 
    GetReOrgAppealMissionFiles,
    GetReOrgAppealMissionResponseFile,
    GetReOrgAppealMissionResponseFiles,  
    ClearReOrgAppealMissionCRUDValues,
    ExportReOrgAppealMission
}
  
function CreateReOrgAppealMission(params){
    const dispatchObj = {
        constants : reOrgAppealMissionsConstants.CreateReOrgAppealMission,
        service   : {
            func   : api.CreateReOrgAppealMission,
            params : params
        }
    }
    return defAction(dispatchObj)
}

function ReadReOrgAppealMission(params){
    const dispatchObj = {
        constants : reOrgAppealMissionsConstants.ReadReOrgAppealMission,
        service   : {
            func   : api.ReadReOrgAppealMission,
            params : params
        }
    }
    return defAction(dispatchObj)
}

function RegisterReOrgAppealMission(params){
    const dispatchObj = {
        constants : reOrgAppealMissionsConstants.RegisterReOrgAppealMission,
        service   : {
            func   : api.RegisterReOrgAppealMission,
            params : params
        }
    }
    return defAction(dispatchObj)
}

function UpdateReOrgAppealMissionByReceiver(params){
    const dispatchObj = {
        constants : reOrgAppealMissionsConstants.UpdateReOrgAppealMissionByReceiver,
        service   : {
            func   : api.UpdateReOrgAppealMissionByReceiver,
            params : params
        }
    }
    return defAction(dispatchObj)
}

function CreateReOrgAppealMissionResponse(params){
    const dispatchObj = {
        constants : reOrgAppealMissionsConstants.CreateReOrgAppealMissionResponse,
        service   : {
            func   : api.CreateReOrgAppealMissionResponse,
            params : params
        }
    }
    return defAction(dispatchObj)
}

function GetReOrgAppealMissionsList(params){
    const dispatchObj = {
        constants : reOrgAppealMissionsConstants.GetReOrgAppealMissionsList,
        service   : {
            func   : api.GetReOrgAppealMissionsList,
            params : params
        }
    }
    return defAction(dispatchObj)
}

function GetReOrgAppealMissionsPaged(params){
    const dispatchObj = {
        constants : reOrgAppealMissionsConstants.GetReOrgAppealMissionsPaged,
        service   : {
            func   : api.GetReOrgAppealMissionsPaged,
            params : params
        }
    }
    return defAction(dispatchObj)
}

function GetReOrgAppealMissionsForReOrgAppealPaged(params){
    const dispatchObj = {
        constants : reOrgAppealMissionsConstants.GetReOrgAppealMissionsForReOrgAppealPaged,
        service   : {
            func   : api.GetReOrgAppealMissionsForReOrgAppealPaged,
            params : params
        }
    }
    return defAction(dispatchObj)
}

function GetReOrgAppealMissionFile(params, file){
    const dispatchObj = {
        constants : reOrgAppealMissionsConstants.GetReOrgAppealMissionFile,
        service   : {
            func   : api.GetReOrgAppealMissionFile,
            params : params
        },
        sucFunction: (res) => {
			downloadFile(res,{fileName: file.fileName, fileExtension: file.fileExtension})
		}
    }
    return defAction(dispatchObj)
}

function GetReOrgAppealMissionFiles(params, fileName){
    const dispatchObj = {
        constants : reOrgAppealMissionsConstants.GetReOrgAppealMissionFiles,
        service   : {
            func   : api.GetReOrgAppealMissionFiles,
            params : params
        },
        sucFunction: (res) => {
			downloadFile(res, {fileName: fileName, fileExtension: ".zip"})
		}
    }
    return defAction(dispatchObj)
}

function GetReOrgAppealMissionResponseFile(params, file){
    const dispatchObj = {
        constants : reOrgAppealMissionsConstants.GetReOrgAppealMissionResponseFile,
        service   : {
            func   : api.GetReOrgAppealMissionResponseFile,
            params : params
        },
        sucFunction: (res) => {
			downloadFile(res,{fileName: file.fileName, fileExtension: file.fileExtension})
		}
    }
    return defAction(dispatchObj)
}

function GetReOrgAppealMissionResponseFiles(params, fileName){
    const dispatchObj = {
        constants : reOrgAppealMissionsConstants.GetReOrgAppealMissionResponseFiles,
        service   : {
            func   : api.GetReOrgAppealMissionResponseFiles,
            params : params
        },
        sucFunction: (res) => {
			downloadFile(res, {fileName: fileName, fileExtension: ".zip"})
		}
    }
    return defAction(dispatchObj)
}

function ExportReOrgAppealMission(params, fileName){
    const dispatchObj = {
        constants : reOrgAppealMissionsConstants.ExportReOrgAppealMission,
        service   : {
            func   : api.ExportReOrgAppealMission,
            params : params
        },
        sucFunction: (res) => {
			downloadFile(res,{fileName: fileName, fileExtension: ".docx"})
		}
    }
    return defAction(dispatchObj)
}

function ClearReOrgAppealMissionCRUDValues(){
    return (dispatch) => 
      dispatch({
        type: reOrgAppealMissionsConstants.ClearReOrgAppealMissionCRUDValues
    })
}
export const reOrgAppealMissionsConstants = {
	CreateReOrgAppealMission: { 
		REQUEST : 'CREATE_RE_ORG_APPEAL_MISSION_REQUEST', 
		SUCCESS : 'CREATE_RE_ORG_APPEAL_MISSION_SUCCESS',
		FAILURE : 'CREATE_RE_ORG_APPEAL_MISSION_FAILURE',
		CLEAR   : 'CREATE_RE_ORG_APPEAL_MISSION_CLEAR'
	},
	ReadReOrgAppealMission: {
		REQUEST : 'READ_RE_ORG_APPEAL_MISSION_REQUEST',
		SUCCESS : 'READ_RE_ORG_APPEAL_MISSION_SUCCESS',
		FAILURE : 'READ_RE_ORG_APPEAL_MISSION_FAILURE',
		CLEAR   : 'READ_RE_ORG_APPEAL_MISSION_CLEAR'
	},
	RegisterReOrgAppealMission: { 
		REQUEST : 'REGISTER_RE_ORG_APPEAL_MISSION_REQUEST', 
		SUCCESS : 'REGISTER_RE_ORG_APPEAL_MISSION_SUCCESS',
		FAILURE : 'REGISTER_RE_ORG_APPEAL_MISSION_FAILURE',
		CLEAR   : 'REGISTER_RE_ORG_APPEAL_MISSION_CLEAR'
	},
	UpdateReOrgAppealMissionByReceiver: { 
		REQUEST : 'UPDATE_RE_ORG_APPEAL_MISSION_BY_RECEIVER_REQUEST', 
		SUCCESS : 'UPDATE_RE_ORG_APPEAL_MISSION_BY_RECEIVER_SUCCESS',
		FAILURE : 'UPDATE_RE_ORG_APPEAL_MISSION_BY_RECEIVER_FAILURE',
		CLEAR   : 'UPDATE_RE_ORG_APPEAL_MISSION_BY_RECEIVER_CLEAR'
	},
	CreateReOrgAppealMissionResponse: { 
		REQUEST : 'CREATE_RE_ORG_APPEAL_MISSION_RESPONSE_REQUEST', 
		SUCCESS : 'CREATE_RE_ORG_APPEAL_MISSION_RESPONSE_SUCCESS',
		FAILURE : 'CREATE_RE_ORG_APPEAL_MISSION_RESPONSE_FAILURE',
		CLEAR   : 'CREATE_RE_ORG_APPEAL_MISSION_RESPONSE_CLEAR'
	},
	GetReOrgAppealMissionsList: { 
		REQUEST : 'GET_RE_ORG_APPEAL_MISSIONS_LIST_REQUEST', 
		SUCCESS : 'GET_RE_ORG_APPEAL_MISSIONS_LIST_SUCCESS',
		FAILURE : 'GET_RE_ORG_APPEAL_MISSIONS_LIST_FAILURE',
		CLEAR   : 'GET_RE_ORG_APPEAL_MISSIONS_LIST_CLEAR'
	},
	GetReOrgAppealMissionsPaged: { 
		REQUEST : 'GET_RE_ORG_APPEAL_MISSIONS_PAGED_REQUEST', 
		SUCCESS : 'GET_RE_ORG_APPEAL_MISSIONS_PAGED_SUCCESS',
		FAILURE : 'GET_RE_ORG_APPEAL_MISSIONS_PAGED_FAILURE',
		CLEAR   : 'GET_RE_ORG_APPEAL_MISSIONS_PAGED_CLEAR'
	},
	GetReOrgAppealMissionsForReOrgAppealPaged: {
		REQUEST : 'GET_RE_ORG_APPEAL_MISSIONS_FOR_RE_ORG_APPEAL_PAGED_REQUEST', 
		SUCCESS : 'GET_RE_ORG_APPEAL_MISSIONS_FOR_RE_ORG_APPEAL_PAGED_SUCCESS',
		FAILURE : 'GET_RE_ORG_APPEAL_MISSIONS_FOR_RE_ORG_APPEAL_PAGED_FAILURE',
		CLEAR   : 'GET_RE_ORG_APPEAL_MISSIONS_FOR_RE_ORG_APPEAL_PAGED_CLEAR'
	},
	GetReOrgAppealMissionFile: { 
		REQUEST : 'GET_RE_ORG_APPEAL_MISSION_FILE_REQUEST', 
		SUCCESS : 'GET_RE_ORG_APPEAL_MISSION_FILE_SUCCESS',
		FAILURE : 'GET_RE_ORG_APPEAL_MISSION_FILE_FAILURE',
		CLEAR   : 'GET_RE_ORG_APPEAL_MISSION_FILE_CLEAR'
	},
	GetReOrgAppealMissionFiles: { 
		REQUEST : 'GET_RE_ORG_APPEAL_MISSION_FILES_REQUEST', 
		SUCCESS : 'GET_RE_ORG_APPEAL_MISSION_FILES_SUCCESS',
		FAILURE : 'GET_RE_ORG_APPEAL_MISSION_FILES_FAILURE',
		CLEAR   : 'GET_RE_ORG_APPEAL_MISSION_FILES_CLEAR'
	},
	GetReOrgAppealMissionResponseFile: { 
		REQUEST : 'GET_RE_ORG_APPEAL_MISSION_RESPONSE_FILE_REQUEST', 
		SUCCESS : 'GET_RE_ORG_APPEAL_MISSION_RESPONSE_FILE_SUCCESS',
		FAILURE : 'GET_RE_ORG_APPEAL_MISSION_RESPONSE_FILE_FAILURE',
		CLEAR   : 'GET_RE_ORG_APPEAL_MISSION_RESPONSE_FILE_CLEAR'
	},
	GetReOrgAppealMissionResponseFiles: { 
		REQUEST : 'GET_RE_ORG_APPEAL_MISSION_RESPONSE_FILES_REQUEST', 
		SUCCESS : 'GET_RE_ORG_APPEAL_MISSION_RESPONSE_FILES_SUCCESS',
		FAILURE : 'GET_RE_ORG_APPEAL_MISSION_RESPONSE_FILES_FAILURE',
		CLEAR   : 'GET_RE_ORG_APPEAL_MISSION_RESPONSE_FILES_CLEAR'
	},
	ExportReOrgAppealMission: {
		REQUEST : 'EXPORT_RE_ORG_APPEAL_MISSION_REQUEST',
		SUCCESS : 'EXPORT_RE_ORG_APPEAL_MISSION_SUCCESS',
		FAILURE : 'EXPORT_RE_ORG_APPEAL_MISSION_FAILURE',
		CLEAR   : 'EXPORT_RE_ORG_APPEAL_MISSION_CLEAR'
	},
	ClearReOrgAppealMissionCRUDValues: 'CLEAR_RE_ORG_APPEAL_MISSION_CRUD_VALUES',
}

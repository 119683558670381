import {
	resolutionsConstants
}from './constants'

const initialState = {
	error                             : null,
	listExecutors                     : [], // Список исполнителей резолюции
	listExecutorsLoading			  : false,
	listResponsibleManagers			  : [], // Список ответственных исполнителей
	listResponsibleManagersLoading	  : false,
	listSigners  					  : [], // Список ответственных руководителей резолюции
	listSignersLoading 				  : false,
	listResolutions                   : [], // Список резолюций и проектов резолюций
	listResolutionsLoading		      : false,
	resolutionForm 					  : null, // Данные формы редактирования/подписания резолюции или проекта резолюции
	resolutionFormLoading			  : false, 
	resolutionActionsResult			  : { // Результат действия с резолюцией
		create	: null,					  // создание,
		edit    : null,					  // редактирование,
		delete  : null,					  // удаление,
		createProject : null,			  // создание проекта,
		editProject : null,				  // редактирование проекта,
		deleteProject  : null,			  // удаление проекта,
		signProject    : null,			  // подписание проекта
		declineProject : null			  // отклонение проекта
	},
	resolutionActionsResultLoading	  : false,
	downloadResolutionFileLoading     : false,// Loading для загрузки файла резолюции
	resolutionInfo					  : null, // Основная информация по резолюции для страницы
	resolutionInfoLoading			  : false, 
	resolutionExecutorsList 		  : null, // Cписок исполнителей с их ответами
	resolutionExecutorsListLoading	  : false, 
	resolutionSummaryResult			  : null, // Сводный результат резолюции
	resolutionSummaryResultLoading 	  : false,
	intermediateAppealResultsPaged	  : null, // Список промежуточных результатов обращения
	intermediateAppealResultsPagedLoading  : false,
	intermediateAppealResult	  	  : null, // Промежуточный результат обращения
	intermediateAppealResultLoading   : false,
	resolutionExecutorResultActionsResult : { // Результат действия с ответом исполнителя
		create : null,						  // создание
		update : null,						  // редактирование
		approve : null,						  // согласование
		decline : null						  // отклонение
	},
	resolutionExecutorResultActionsResultLoading : false,
	resolutionSummaryResultActionsResult : {  // Результат действия со сводным результатом резолюции
		create : null,						  // создание
		update : null,						  // редактирование
		sendToSign : null,					  // отправка сводного результата на подписание
		sendForRevision : null,				  // отправка сводного результата на доработку
		sign : null							  // подписание
	},
	resolutionSummaryResultActionsResultLoading  : false,
	intermediateAppealResultActionsResult : { // Результат действия с промежуточным результатом обрвщения
		create : null,						  // создание
		update : null,						  // редактирование
		sendForRevision : null,				  // отправка промежуточного результата на доработку
		sign 	: null						  // подписание
	},
	intermediateAppealResultActionsResultLoading : false,
	resolutionFilesInfo : null,				  // Список base64 файлов резолюции для формы подписания и редактирования 
	resolutionFilesInfoLoading : false,		
	summaryResultFilesInfo : null,			  // Список base64 файлов сводного результата для подписания
	summaryResultFilesInfoLoading : false,
	intermediateResultFilesInfo : null, 	  // Список base64 файлов промежуточного результата для подписания  
	intermediateResultFilesInfoLoading : false, 
	resolutionChangeHistory           : [],	  // История изменения резолюций
	getResolutionChangeHistorySending : false,
}

export default function (state = initialState, action){
	switch (action.type){
		//#region Resolution
		// GetResolutions
		case resolutionsConstants.GetResolutions.REQUEST:
			return {
				...state,
				listResolutionsLoading : true,
			}
		case resolutionsConstants.GetResolutions.SUCCESS:
			return {
				...state,
				listResolutionsLoading : false,
				listResolutions : action.payload.result
			}
		case resolutionsConstants.GetResolutions.FAILURE:
			return {
				...state,
				listResolutionsLoading : false,
				error           : action.payload
			}
		case resolutionsConstants.GetResolutions.CLEAR:
			return {
				...state,
				listResolutionsLoading : false,
				listResolutions : []
			}
		// GetResolutionForm
		case resolutionsConstants.GetResolutionForm.REQUEST:
			return {
				...state,
				resolutionFormLoading       : true,
			}
		case resolutionsConstants.GetResolutionForm.SUCCESS:
			return {
				...state,
				resolutionFormLoading       : false,
				resolutionForm : action.payload.result
			}
		case resolutionsConstants.GetResolutionForm.FAILURE:
			return {
				...state,
				resolutionFormLoading       : false,
				error           : action.payload
			}
		case resolutionsConstants.GetResolutionForm.CLEAR:
			return {
				...state,
				resolutionForm : null,
				resolutionFormLoading : false
			}
		//CreateResolution, EditResolution, EditResolutionProject, DeleteResolution, SignResolution, DeclineResolution
		case resolutionsConstants.CreateResolution.REQUEST:
		case resolutionsConstants.EditResolution.REQUEST:
		case resolutionsConstants.EditResolutionProject.REQUEST:
		case resolutionsConstants.DeleteResolution.REQUEST:
		case resolutionsConstants.SignResolution.REQUEST:
		case resolutionsConstants.DeclineResolution.REQUEST:
			return {
				...state,
				resolutionActionsResultLoading : true,
			}
		case resolutionsConstants.CreateResolution.FAILURE:
		case resolutionsConstants.EditResolution.FAILURE:
		case resolutionsConstants.EditResolutionProject.FAILURE:
		case resolutionsConstants.DeleteResolution.FAILURE:
		case resolutionsConstants.SignResolution.FAILURE:
		case resolutionsConstants.DeclineResolution.FAILURE:
			return {
				...state,
				resolutionActionsResultLoading : false,
				error     : action.payload
			}
		//CreateResolution
		case resolutionsConstants.CreateResolution.SUCCESS:
		
			return {
				...state,
				resolutionActionsResultLoading : false,
				resolutionActionsResult : {
					create	: action.payload.sucParams.isProject ? null : action.payload.result,
					edit    : null,
					delete  : null,
					createProject : action.payload.sucParams.isProject ? action.payload.result : null,
					editProject : null,
					deleteProject  : null,
					signProject    : null,
					declineProject : null
				}
			}	
		//EditResolution
		case resolutionsConstants.EditResolution.SUCCESS:
			return {
				...state,
				resolutionActionsResultLoading : false,
				resolutionActionsResult : {
					create	: null,
					edit    : action.payload.result,
					delete  : null,
					createProject : null,
					editProject : null,
					deleteProject  : null,
					signProject    : null,
					declineProject : null
				}
			}	
		//EditResolutionProject
		case resolutionsConstants.EditResolutionProject.SUCCESS:
			return {
				...state,
				resolutionActionsResultLoading : false,
				resolutionActionsResult : {
					create	: null,
					edit    : null,
					delete  : null,
					createProject : null,
					editProject : action.payload.result,
					deleteProject  : null,
					signProject    : null,
					declineProject : null
				}
			}	
		//DeleteResolution
		case resolutionsConstants.DeleteResolution.SUCCESS:
			return {
				...state,
				resolutionActionsResultLoading : false,
				resolutionActionsResult : {
					create	: null,
					edit    : null,
					delete  : action.payload.sucParams.isProject ? null : action.payload.result,
					createProject : null,
					editProject : null,
					deleteProject  : action.payload.sucParams.isProject ? action.payload.result : null,
					signProject    : null,
					declineProject : null
				}
			}	
		//SignResolution
		case resolutionsConstants.SignResolution.SUCCESS:
			return {
				...state,
				resolutionActionsResultLoading : false,
				resolutionActionsResult : {
					create	: null,
					edit    : null,
					delete  : null,
					createProject : null,
					editProject : null,
					deleteProject  : null,
					signProject    : action.payload.result,
					declineProject : null
				}
			}	
		//DeclineResolution
		case resolutionsConstants.DeclineResolution.SUCCESS:
			return {
				...state,
				resolutionActionsResultLoading : false,
				resolutionActionsResult : {
					create	: null,
					edit    : null,
					delete  : null,
					createProject : null,
					editProject : null,
					deleteProject  : null,
					signProject    : null,
					declineProject : action.payload.result
				}
			}	
		// ClearResolutionActionsResult
		case resolutionsConstants.ClearResolutionActionsResult: 
			return {
				...state,
				resolutionActionsResult : {
					create	: null,
					edit    : null,
					delete  : null,
					createProject : null,
					editProject : null,
					deleteProject  : null,
					signProject    : null,
					declineProject : null
				}
			}
		// DownloadResolutionFile
		case resolutionsConstants.DownloadResolutionFile.REQUEST:
			return {
				...state,
				downloadResolutionFileLoading      : true
			}
		case resolutionsConstants.DownloadResolutionFile.SUCCESS:
			return {
				...state,
				downloadResolutionFileLoading      : false
			}
		case resolutionsConstants.DownloadResolutionFile.FAILURE:
			return {
				...state,
				error             : action.payload
			}
		// GetResolutionChangeHistory
		case resolutionsConstants.GetResolutionChangeHistory.REQUEST:
			return {
				...state,
				getResolutionChangeHistorySending: true
			}
		case resolutionsConstants.GetResolutionChangeHistory.SUCCESS:
			return {
				...state,
				getResolutionChangeHistorySending : false,
				resolutionChangeHistory           : action.payload.result
			}
		case resolutionsConstants.GetResolutionChangeHistory.FAILURE:
			return {
				...state,
				getResolutionChangeHistorySending : false,
				error                             : action.payload,
				resolutionChangeHistory           : []
			}
		// GetResolutionFilesInfo
		case resolutionsConstants.GetResolutionFilesInfo.REQUEST:
			return {
				...state,
				resolutionFilesInfoLoading : true,
			}
		case resolutionsConstants.GetResolutionFilesInfo.SUCCESS:
			return {
				...state,
				resolutionFilesInfoLoading : false,
				resolutionFilesInfo : {
					isEdit : action.payload.sucParams.isEdit,
					files : action.payload.result
				},
			}
		case resolutionsConstants.GetResolutionFilesInfo.FAILURE:
			return {
				...state,
				resolutionFilesInfoLoading : false,
				error : action.payload,

			}
		case resolutionsConstants.GetResolutionFilesInfo.CLEAR:
			return {
				...state,
				resolutionFilesInfoLoading : false,
				resolutionFilesInfo : null,
			}
		//#endRegion
		//#region ResolutionInfo
		// GetResolutionInfo
		case resolutionsConstants.GetResolutionInfo.REQUEST:
			return {
				...state,
				resolutionInfoLoading	: true,
			}
		case resolutionsConstants.GetResolutionInfo.SUCCESS:
			return {
				...state,
				resolutionInfoLoading   : false,
				resolutionInfo 			: action.payload.result
			}
		case resolutionsConstants.GetResolutionInfo.FAILURE:
			return {
				...state,
				resolutionInfoLoading	: false,
				error           : action.payload
			}
		case resolutionsConstants.GetResolutionInfo.CLEAR:
			return {
				...state,
				resolutionInfo : null,
				resolutionInfoLoading : false
			}
		//#endRegion
		//#region ExecutorResult
		// GetResolutionExecutorsList 
		case resolutionsConstants.GetResolutionExecutorsList.REQUEST:
			return {
				...state,
				resolutionExecutorsListLoading	: true,
			}
		case resolutionsConstants.GetResolutionExecutorsList.SUCCESS:
			return {
				...state,
				resolutionExecutorsListLoading   : false,
				resolutionExecutorsList 		 : action.payload.result
			}
		case resolutionsConstants.GetResolutionExecutorsList.FAILURE:
			return {
				...state,
				resolutionExecutorsListLoading	: false,
				error           : action.payload
			}
		case resolutionsConstants.GetResolutionExecutorsList.CLEAR:
			return {
				...state,
				resolutionExecutorsList 		: null,
				resolutionExecutorsListLoading  : false
			}
		// CreateExecutionResult, UpdateExecutionResult, ApproveExecutionResult, DeclineExecutionResult
		case resolutionsConstants.CreateExecutionResult.REQUEST:
		case resolutionsConstants.UpdateExecutionResult.REQUEST:
		case resolutionsConstants.ApproveExecutionResult.REQUEST:
		case resolutionsConstants.DeclineExecutionResult.REQUEST:
			return {
				...state,
				resolutionExecutorResultActionsResultLoading : true,
			}
		case resolutionsConstants.CreateExecutionResult.FAILURE:
		case resolutionsConstants.UpdateExecutionResult.FAILURE:	
		case resolutionsConstants.ApproveExecutionResult.FAILURE:
		case resolutionsConstants.DeclineExecutionResult.FAILURE:
			return {
				...state,
				resolutionExecutorResultActionsResultLoading : false,
				error                        : action.payload,
			}
		// CreateExecutionResult
		case resolutionsConstants.CreateExecutionResult.SUCCESS:
			return {
				...state,
				resolutionExecutorResultActionsResultLoading : false,
				resolutionExecutorResultActionsResult : {
					create : action.payload.result,
					update : null,								 
					approve : null,						  
					decline : null			
				}
			}
		// UpdateExecutionResult
		case resolutionsConstants.UpdateExecutionResult.SUCCESS:
			return {
				...state,
				resolutionExecutorResultActionsResultLoading : false,
				resolutionExecutorResultActionsResult : {
					create : null,
					update : action.payload.result,								 
					approve : null,						  
					decline : null			
				}
			}
		// ApproveExecutionResult
		case resolutionsConstants.ApproveExecutionResult.SUCCESS:
			return {
				...state,
				resolutionExecutorResultActionsResultLoading : false,
				resolutionExecutorResultActionsResult : {
					create : null,
					update : null,								 
					approve : action.payload.result,
					decline : null,		
				}
			}
		// DeclineExecutionResult
		case resolutionsConstants.DeclineExecutionResult.SUCCESS:
			return {
				...state,
				resolutionExecutorResultActionsResultLoading : false,
				resolutionExecutorResultActionsResult : {
					create : null,
					update : null,								 
					approve : null,
					decline : action.payload.result,		
				}
			}
		// ClearResolutionExecutorResultActionsResult
		case resolutionsConstants.ClearResolutionExecutorResultActionsResult:
			return {
				...state,
				resolutionExecutorResultActionsResultLoading : false,
				resolutionExecutorResultActionsResult : {
					create : null,
					update : null,								 
					approve : null,
					decline : null,		
				}
			}
		//#endRegion
		//#region SummaryResult
		// GetResolutionSummaryResult  
		case resolutionsConstants.GetResolutionSummaryResult.REQUEST:
			return {
				...state,
				resolutionSummaryResultLoading	: true,
			}
		case resolutionsConstants.GetResolutionSummaryResult.SUCCESS:
			return {
				...state,
				resolutionSummaryResultLoading   : false,
				resolutionSummaryResult 		 : action.payload.result
			}
		case resolutionsConstants.GetResolutionSummaryResult.FAILURE:
			return {
				...state,
				resolutionSummaryResultLoading	: false,
				error           : action.payload
			}
		case resolutionsConstants.GetResolutionSummaryResult.CLEAR:
			return {
				...state,
				resolutionSummaryResult 		: null,
				resolutionSummaryResultLoading  : false
			}
		// CreateSummaryResult, UpdateSummaryResult, SendSummaryResultToSign, SendSummaryResultForRevision, SignSummaryResult
		case resolutionsConstants.CreateSummaryResult.REQUEST:
		case resolutionsConstants.UpdateSummaryResult.REQUEST:
		case resolutionsConstants.SendSummaryResultToSign.REQUEST:
		case resolutionsConstants.SendSummaryResultForRevision.REQUEST:
		case resolutionsConstants.SignSummaryResult.REQUEST:
			return {
				...state,
				resolutionSummaryResultActionsResultLoading : true,
			}
		case resolutionsConstants.CreateSummaryResult.FAILURE:
		case resolutionsConstants.UpdateSummaryResult.FAILURE:
		case resolutionsConstants.SendSummaryResultToSign.FAILURE:
		case resolutionsConstants.SendSummaryResultForRevision.FAILURE:
		case resolutionsConstants.SignSummaryResult.FAILURE:
			return {
				...state,
				resolutionSummaryResultActionsResultLoading : false,
				error                        : action.payload,
			}
		// CreateSummaryResult
		case resolutionsConstants.CreateSummaryResult.SUCCESS:
			return {
				...state,
				resolutionSummaryResultActionsResultLoading : false,
				resolutionSummaryResultActionsResult : {
					create : action.payload.result,
					update : null,								 
					sendToSign : null,
					sendForRevision : null,	
					sign : null		
				}
			}
		// UpdateSummaryResult
		case resolutionsConstants.UpdateSummaryResult.SUCCESS:
			return {
				...state,
				resolutionSummaryResultActionsResultLoading : false,
				resolutionSummaryResultActionsResult : {
					create : null,
					update : action.payload.result,								 
					sendToSign : null,
					sendForRevision : null,	
					sign : null		
				}
			}
		// SendSummaryResultToSign
		case resolutionsConstants.SendSummaryResultToSign.SUCCESS:
			return {
				...state,
				resolutionSummaryResultActionsResultLoading : false,
				resolutionSummaryResultActionsResult : {  
					create : null,						  
					update : null,						  
					sendToSign : action.payload.result,					  
					sendForRevision : null,				  
					sign : null							 
				}
			}
		// SendSummaryResultForRevision
		case resolutionsConstants.SendSummaryResultForRevision.SUCCESS:
			return {
				...state,
				resolutionSummaryResultActionsResultLoading : false,
				resolutionSummaryResultActionsResult : {  
					create : null,						  
					update : null,						  
					sendToSign : null,					  
					sendForRevision : action.payload.result,				  
					sign : null							 
				}
			}
		// SignSummaryResult
		case resolutionsConstants.SignSummaryResult.SUCCESS:
			return {
				...state,
				resolutionSummaryResultActionsResultLoading : false,
				resolutionSummaryResultActionsResult : {  
					create : null,						  
					update : null,						  
					sendToSign : null,					  
					sendForRevision : null,				  
					sign : action.payload.result							 
				}
			}
		// ClearResolutionSummaryResultActionsResult
		case resolutionsConstants.ClearResolutionSummaryResultActionsResult:
			return {
				...state,
				resolutionSummaryResultActionsResultLoading : false,
				resolutionSummaryResultActionsResult : {
					create : null,
					update : null,								 
					sendToSign : null,
					sendForRevision : null,	
					sign : null		
				}
			}
		// GetSummaryResultFilesInfo
		case resolutionsConstants.GetSummaryResultFilesInfo.REQUEST:
			return {
				...state,
				summaryResultFilesInfoLoading : true,
			}
		case resolutionsConstants.GetSummaryResultFilesInfo.SUCCESS:
			return {
				...state,
				summaryResultFilesInfoLoading : false,
				summaryResultFilesInfo: action.payload.result
			}
		case resolutionsConstants.GetSummaryResultFilesInfo.FAILURE:
			return {
				...state,
				summaryResultFilesInfoLoading : false,
				error: action.payload
			}
		case resolutionsConstants.GetSummaryResultFilesInfo.CLEAR:
			return {
				...state,
				summaryResultFilesInfoLoading : false,
				summaryResultFilesInfo : null,
			}
		//#endRegion
		//#region IntermediateResult
		// GetIntermediateAppealResultsPaged   
		case resolutionsConstants.GetIntermediateAppealResultsPaged.REQUEST:
			return {
				...state,
				intermediateAppealResultsPagedLoading	: true,
			}
		case resolutionsConstants.GetIntermediateAppealResultsPaged.SUCCESS:
			return {
				...state,
				intermediateAppealResultsPagedLoading   : false,
				intermediateAppealResultsPaged 		   : action.payload.result
			}
		case resolutionsConstants.GetIntermediateAppealResultsPaged.FAILURE:
			return {
				...state,
				intermediateAppealResultsPagedLoading	: false,
				error           : action.payload
			}
		case resolutionsConstants.GetIntermediateAppealResultsPaged.CLEAR:
			return {
				...state,
				intermediateAppealResultsPaged 		: null,
				intermediateAppealResultsPagedLoading  : false
			}
		// GetIntermediateAppealResult   
		case resolutionsConstants.GetIntermediateAppealResult.REQUEST:
			return {
				...state,
				intermediateAppealResultLoading	: true,
			}
		case resolutionsConstants.GetIntermediateAppealResult.SUCCESS:
			return {
				...state,
				intermediateAppealResultLoading : false,
				intermediateAppealResult 		: action.payload.result
			}
		case resolutionsConstants.GetIntermediateAppealResult.FAILURE:
			return {
				...state,
				intermediateAppealResultLoading	: false,
				error           				: action.payload
			}
		case resolutionsConstants.GetIntermediateAppealResult.CLEAR:
			return {
				...state,
				intermediateAppealResult 		: null,
				intermediateAppealResultLoading : false
			}
		// CreateIntermediateAppealResult, UpdateIntermediateAppealResult, SendIntermediateAppealResultForRevision, SignIntermediateAppealResult
		case resolutionsConstants.CreateIntermediateAppealResult.REQUEST:
		case resolutionsConstants.UpdateIntermediateAppealResult.REQUEST:
		case resolutionsConstants.SendIntermediateAppealResultForRevision.REQUEST:
		case resolutionsConstants.SignIntermediateAppealResult.REQUEST:
			return {
				...state,
				intermediateAppealResultActionsResultLoading : true,
			}
		case resolutionsConstants.CreateIntermediateAppealResult.FAILURE:
		case resolutionsConstants.UpdateIntermediateAppealResult.FAILURE:
		case resolutionsConstants.SendIntermediateAppealResultForRevision.FAILURE:
		case resolutionsConstants.SignIntermediateAppealResult.FAILURE:
			return {
				...state,
				intermediateAppealResultActionsResultLoading : false,
				error                        : action.payload,
			}
		// CreateIntermediateAppealResult
		case resolutionsConstants.CreateIntermediateAppealResult.SUCCESS:
			return {
				...state,
				intermediateAppealResultActionsResultLoading : false,
				intermediateAppealResultActionsResult : {
					create : action.payload.result,
					update : null,	
					sendForRevision : null,	
					sign : null		
				}
			}
		// UpdateIntermediateAppealResult
		case resolutionsConstants.UpdateIntermediateAppealResult.SUCCESS:
			return {
				...state,
				intermediateAppealResultActionsResultLoading : false,
				intermediateAppealResultActionsResult : {
					create : null,
					update : action.payload.result,	
					sendForRevision : null,	
					sign : null		
				}
			}	
		// SendIntermediateAppealResultForRevision
		case resolutionsConstants.SendIntermediateAppealResultForRevision.SUCCESS:
			return {
				...state,
				intermediateAppealResultActionsResultLoading : false,
				intermediateAppealResultActionsResult : {
					create : null,
					update : null,	
					sendForRevision : action.payload.result,
					sign : null		
				}
			}
		// SignIntermediateAppealResult
		case resolutionsConstants.SignIntermediateAppealResult.SUCCESS:
			return {
				...state,
				intermediateAppealResultActionsResultLoading : false,
				intermediateAppealResultActionsResult : {
					create : null,
					update : null,	
					sendForRevision : null,
					sign : action.payload.result		
				}
			}
		// ClearIntermediateAppealResultActionsResult
		case resolutionsConstants.ClearIntermediateAppealResultActionsResult:
			return {
				...state,
				intermediateAppealResultActionsResultLoading : false,
				intermediateAppealResultActionsResult : {
					create : null,
					update : null,		
					sendForRevision : null,	
					sign : null		
				}
			}
		// GetIntermediateAppealResultFilesInfo
		case resolutionsConstants.GetIntermediateAppealResultFilesInfo.REQUEST:
			return {
				...state,
				intermediateResultFilesInfoLoading : true,
			}
		case resolutionsConstants.GetIntermediateAppealResultFilesInfo.SUCCESS:
			return {
				...state,
				intermediateResultFilesInfoLoading : false,
				intermediateResultFilesInfo: action.payload.result
			}
		case resolutionsConstants.GetIntermediateAppealResultFilesInfo.FAILURE:
			return {
				...state,
				intermediateResultFilesInfoLoading : false,
				error: action.payload
			}
		case resolutionsConstants.GetIntermediateAppealResultFilesInfo.CLEAR:
			return {
				...state,
				intermediateResultFilesInfoLoading : false,
				intermediateResultFilesInfo : null,
			}
		//#endRegion	
		default:
			return state
	}
}

export const appealNoticeConstants = {
	EditOrgAppealNotice: {
		REQUEST : "EDIT_ORG_APPEAL_NOTICE_REQUEST",
		SUCCESS : "EDIT_ORG_APPEAL_NOTICE_SUCCESS",
		FAILURE : "EDIT_ORG_APPEAL_NOTICE_FAILURE",
		CLEAR	: "EDIT_ORG_APPEAL_NOTICE_CLEAR"
	},
	GetAppealNotice: {
		REQUEST : "GET_APPEAL_NOTICE_REQUEST",
		SUCCESS : "GET_APPEAL_NOTICE_SUCCESS",
		FAILURE : "GET_APPEAL_NOTICE_FAILURE",
		CLEAR	: "GET_APPEAL_NOTICE_CLEAR",
	},
	DownloadAppealNoticeFile: {
		REQUEST : "DOWNLOAD_APPEAL_NOTICE_FILE_REQUEST",
		SUCCESS : "DOWNLOAD_APPEAL_NOTICE_FILE_SUCCESS",
		FAILURE : "DOWNLOAD_APPEAL_NOTICE_FILE_FAILURE",
	},
	ClearOrgAppealNotice: "CLEAR_ORG_APPEAL_NOTICE"

}

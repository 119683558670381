import {
	appealNoticeConstants
}from "./constants"

const initialState = {
	appealNotice: null,
	appealNoticeLoading: false,
	appealNoticeChanged : null
}

export default function (state = initialState, action){
	switch (action.type){
		//EditOrgAppealNotice
		case appealNoticeConstants.EditOrgAppealNotice.REQUEST:
			return {
				...state,
				appealNoticeLoading : true
			}
		case appealNoticeConstants.EditOrgAppealNotice.FAILURE:
			return {
				...state,
				error : action.payload,
				appealNoticeLoading : false
			}
		case appealNoticeConstants.EditOrgAppealNotice.SUCCESS:
			return {
				...state,
				appealNoticeChanged : action.payload.result,
				appealNoticeLoading : false
			}
		case appealNoticeConstants.EditOrgAppealNotice.CLEAR:
			return {
				...state,
				appealNoticeChanged : null,
				appealNoticeLoading : false
			}
		//GetAppealNotice
		case appealNoticeConstants.GetAppealNotice.REQUEST:
			return {
				...state,
				appealNoticeLoading : true
			}
		case appealNoticeConstants.GetAppealNotice.FAILURE:
			return {
				...state,
				appealNoticeLoading : false,
				error : action.payload,
			}
		case appealNoticeConstants.GetAppealNotice.SUCCESS:
			return {
				...state,
				appealNoticeLoading : false,
				appealNotice: action.payload.result
			}
		case appealNoticeConstants.GetAppealNotice.CLEAR:
			return {
				...state,
				appealNoticeLoading : false,
				appealNotice : null
			}
		//ClearOrgAppealNotice
		case appealNoticeConstants.ClearOrgAppealNotice:
			return {
				...state,
				appealNoticeLoading : false,
				appealNotice : null,
				appealNoticeChanged : null
			}
		default:
			return state
	}
}
